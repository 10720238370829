import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@chakra-ui/react";

import BudgetBar from "./BudgetBar";
import RevenueTree from "./RevenueTree";
import SpendingTree from "./SpendingTree";

import Box from "../components/Box";
import Flex from "../components/Flex";
import useGetData from "../contexts/useGetData";

const Budget = ({
  filterCities,
  category,
  type,
  typeLabel,
  year,
  city,
  cities,
  setCity,
}) => {
  const { intactData: revenue, total: revenueTotal } = useGetData(
    cities,
    category,
    type,
    year,
    "revenue"
  );
  const { intactData: spending, total: spendingTotal } = useGetData(
    cities,
    category,
    type,
    year,
    "spending"
  );

  const revenueBudgetData = useMemo(
    () =>
      revenue &&
      revenue.map((rev) => {
        if (rev.data.length) {
          const total = rev.data.reduce(
            (res, content) => {
              const revenueValue = res?.revenue + +content.value;
              res = {
                name: rev.city,
                type: rev.type,
                id: rev.id,
                revenue: revenueValue,
              };
              return res;
            },
            { revenue: 0, spending: 0 }
          );
          return total;
        } else {
          return { name: rev.city, type: rev.type, id: rev.id, revenue: 0 };
        }
      }),
    [revenue]
  );
  const spendingBudgetData = useMemo(
    () =>
      spending &&
      spending.map((rev) => {
        if (rev.data.length) {
          const total = rev.data.reduce(
            (res, content) => {
              const spendingValue = res?.spending + +content.value;
              res = {
                name: rev.city,
                type: rev.type,
                id: rev.id,
                spending: spendingValue,
              };
              return res;
            },
            { revenue: 0, spending: 0 }
          );
          return total;
        } else {
          return { name: rev.city, type: rev.type, id: rev.id, spending: 0 };
        }
      }),
    [spending]
  );
  const budgetData = useMemo(
    () =>
      revenueBudgetData &&
      revenueBudgetData.map((d, i) => ({
        ...d,
        spending: spendingBudgetData?.[i].spending,
      })),
    [revenueBudgetData, spendingBudgetData]
  );
  const dataReady = filterCities?.length === budgetData?.length;
  const [iter, setIter] = useState(0);
  useEffect(() => {
    setIter((i) => i + 1);
  }, [year]);
  return (
    <Flex
      borderRadius={"0 0 2em 2em"}
      mb="3em"
      bg="white"
      px="1.625em"
      alignItems="flex-start"
    >
      <Box flex="1">
        <BudgetBar
          category={category}
          data={budgetData}
          dataReady={dataReady}
          type={type}
          typeLabel={typeLabel}
          year={year}
          city={city}
          onSetCity={setCity}
          key={iter}
        />
      </Box>
      <Stack flex="1">
        <RevenueTree
          category={category}
          data={
            city
              ? revenue?.filter((d) => d.city === city[1])[0]?.data
              : revenueTotal
          }
          dataReady={dataReady}
          type={type}
          typeLabel={typeLabel}
          year={year}
          city={city}
        />
        <SpendingTree
          category={category}
          data={
            city
              ? spending?.filter((d) => d.city === city[1])[0]?.data
              : spendingTotal
          }
          dataReady={dataReady}
          type={type}
          typeLabel={typeLabel}
          year={year}
          city={city}
        />
      </Stack>
    </Flex>
  );
};

export default Budget;
