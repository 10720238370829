/* eslint-disable no-loop-func */
import React, { useEffect, useRef, useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
// import { format } from "d3-format";
import { Box, Text } from "@chakra-ui/react";
import round from "lodash/round";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import intersectionBy from "lodash/intersectionBy";
import uniqBy from "lodash/uniqBy";
import styled from "@emotion/styled";

const RADIAN = Math.PI / 180;
const fontSize = 10;
const chartWidth = 1048;
const chartHeight = 800;

const StyleBox = styled(Box)`
  .recharts-pie {
    outline: none;
  }
`;

const LabelText = ({ x, y, children }) => (
  <text
    x={x}
    y={y - 12}
    fontSize={12}
    fill="white"
    textAnchor="middle"
    dominantBaseline="central"
  >
    {children}
  </text>
);

const LabelI = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
  total,
}) => {
  // const midAngle = (endAngle + startAngle) / 2
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const twoLine = name.length > 6;
  return (
    <g style={{ pointerEvents: "none" }}>
      <LabelText x={x} y={y - (twoLine ? 16 : 4)}>
        {twoLine ? name.substring(0, 4) : name}
      </LabelText>
      {twoLine && (
        <LabelText x={x} y={y - 2}>
          {name.substring(4)}
        </LabelText>
      )}
      <LabelText x={x} y={y + 12}>
        {`${(percent * 100).toFixed(2)}%`}
      </LabelText>
    </g>
  );
};

const roundDigits = (num, digits = 4) => round(num, digits);

const getLablePos = (
  cx,
  cy,
  midAngle,
  outerRadius,
  innerRadius,
  isInnerLayer
) => {
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const radiusOffset = outerRadius - innerRadius;
  const theOuterRadius = outerRadius + (isInnerLayer ? radiusOffset : 0);
  const sx = roundDigits(cx + outerRadius * cos);
  const sy = roundDigits(cy + outerRadius * sin);
  const mx = roundDigits(cx + (theOuterRadius + 30) * cos);
  const my = roundDigits(cy + (theOuterRadius + 30) * sin);
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textX = ex + (cos >= 0 ? 1 : -1) * fontSize;
  const textY = ey + 5;
  return {
    sx,
    sy,
    mx,
    my,
    ex,
    ey,
    textX,
    textY,
    r: theOuterRadius,
    textAnchor: cos >= 0 ? "start" : "end",
  };
};

const LabelIII = (props) => {
  const {
    cx,
    cy,
    midAngle,
    outerRadius,
    innerRadius,
    fill,
    name,
    percent,
    value,
    total,
    isInnerLayer,
    index,
    layerIndex,
    shouldHidden,
  } = props;
  if (fill === "transparent" || value === 0 || shouldHidden) return null;
  const { sx, sy, mx, my, ex, ey, textX, textY, r, textAnchor } = getLablePos(
    cx,
    cy,
    midAngle,
    outerRadius,
    innerRadius,
    isInnerLayer
  );

  const textProps = {
    x: textX,
    y: textY,
    children: `${name} ${
      percent < 0.0001 ? "< 0.01" : (percent * 100).toFixed(2)
    }%`,
  };
  return (
    <g
      class="outer-label"
      data-angle={midAngle.toFixed(4)}
      data-r={r}
      data-sx={sx}
      data-sy={sy}
      data-layer-index={layerIndex}
      data-fill={fill}
      opacity={0}
    >
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={"#9FA0A0"}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={"#9FA0A0"} stroke="none" />

      <text
        fill={isInnerLayer ? fill : "#9FA0A0"}
        {...textProps}
        // box={undefined}
        textAnchor={textAnchor}
        fontSize={fontSize * 1.375}
      />
    </g>
  );
};

const CustomLabel = (props) => {
  const Label = props.percent < 0.065 ? LabelIII : LabelI;
  return <Label {...props} isInnerLayer />;
};

const CustomTooltip = ({ active, payload, total }) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0]?.payload?.fill !== "transparent"
  ) {
    return (
      <Box bg="white" p="0.375em 0.75em" borderRadius={"1em"}>
        {payload.map(
          (d) =>
            d.payload.fill !== "#fff" && (
              <Box key={d.name}>
                <Text fontSize={"1.375em"} color={"#585757"}>
                  {d.name}
                </Text>
                <Text fontSize={"2.25em"} color={"#037771"}>
                  {d?.value?.toLocaleString()}
                  <Text as="span" color="#898989" fontSize={"1.125rem"}>
                    {" "}
                    (
                    {(d.value * 100) / +total < 0.01 || +d?.value < 0
                      ? "< 0.01%"
                      : `${round((d.value * 100) / +total, 2)}%`}
                    )
                  </Text>
                </Text>
              </Box>
            )
        )}
      </Box>
    );
  }

  return null;
};

const checkIsCrossZero = (angles) => {
  const minAngle = Math.min(...angles);
  const maxAngle = Math.max(...angles);
  return maxAngle - minAngle > 180;
};

const checkIsOverlapped = (thisBox, otherBox, thres = 0.8) => {
  const collidingX =
    thisBox.x < otherBox.x + otherBox.width &&
    thisBox.x + thisBox.width > otherBox.x;
  const collidingY =
    thisBox.y < otherBox.y + otherBox.height * thres &&
    thisBox.y + thisBox.height * thres > otherBox.y;
  return collidingX && collidingY;
};

const updateLabel = (d, angle) => {
  const pos = getLablePos(chartWidth / 2, chartHeight / 2, angle, d.r);
  const pathEle = d.ele.querySelector("path");
  pathEle.setAttribute(
    "d",
    `M${d.sx},${d.sy}L${pos.mx},${pos.my}L${pos.ex},${pos.ey}`
  );
  const circleEle = d.ele.querySelector("circle");
  circleEle.setAttribute("cx", pos.ex);
  circleEle.setAttribute("cy", pos.ey);
  const textEle = d.ele.querySelector("text");
  textEle.setAttribute("x", pos.textX);
  textEle.setAttribute("y", pos.textY);
  textEle.setAttribute("text-anchor", pos.textAnchor);
  return textEle;
};

const translateLabel = (d, offsetX = 0, offsetY = 0) => {
  const pathEle = d.ele.querySelector("path");
  const path = pathEle.getAttribute("d");
  const segs = path.split("L");
  const newPath = [
    segs[0],
    ...segs.slice(1).map((seg) => {
      const [x, y] = seg.split(",");
      return `${+x + offsetX},${+y + offsetY}`;
    }),
  ].join("L");
  pathEle.setAttribute("d", newPath);

  const circleEle = d.ele.querySelector("circle");
  const textEle = d.ele.querySelector("text");
  if (offsetX) {
    circleEle.setAttribute("cx", +circleEle.getAttribute("cx") + offsetX);
    textEle.setAttribute("x", +textEle.getAttribute("x") + offsetX);
  }
  if (offsetY) {
    circleEle.setAttribute("cy", +circleEle.getAttribute("cy") + offsetY);
    textEle.setAttribute("y", +textEle.getAttribute("y") + offsetY);
  }
  d.box = textEle.getBBox();
};

const getCollidingGroups = (labels, thres = 0.8) => {
  const collidingLabels = [];

  // 逐一檢查是否有重疊，若有則加入 collidingLabels，包含自己
  labels.forEach((thisLabel, i) => {
    const colliding = [];
    labels.forEach((otherLabel, j) => {
      if (i !== j) {
        const thisBox = thisLabel.box;
        const otherBox = otherLabel.box;
        if (checkIsOverlapped(thisBox, otherBox, thres)) {
          colliding.push(otherLabel);
        }
      }
    });
    if (colliding.length) {
      collidingLabels.push(sortBy([thisLabel, ...colliding], "angle"));
    }
  });

  // 在每組 collidingLabels 中，找出重疊的群集
  const collidingGroups = collidingLabels.reduce((acc, curr) => {
    if (!acc.length) {
      return [curr];
    }
    const intersectIndex = acc.findIndex(
      (d) => intersectionBy(d, curr, "index").length
    );
    if (intersectIndex > -1) {
      acc[intersectIndex] = uniqBy([...acc[intersectIndex], ...curr], "index");
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
  return collidingGroups;
};

const PieChartModule = ({ total, pieData = [], layerToggle, dataKey }) => {
  const [inited, setInited] = useState();
  const [activeSlice, setActiveSlice] = useState([]);
  const modfyPieData = pieData.map((d, i, { length }) => ({
    data: d,
    innerRadius: (length > 2 ? 80 : 100) * (i + 1),
    outerRadius: (length > 2 ? 80 : 100) * (i + 2),
    Label: i === length - 1 ? LabelIII : CustomLabel,
  }));
  useEffect(() => {
    if (activeSlice.length) setInited(true);
  }, [activeSlice.length]);
  const containerRef = useRef();
  const onCalcOverlap = () => {
    if (containerRef.current?.container) {
      let tries = 0;
      let collidingGroups;
      const labels = [];
      do {
        // 找出所有標籤
        containerRef.current.container
          .querySelectorAll(".outer-label")
          .forEach((d, index) => {
            const textEle = d.querySelector("text");
            const box = textEle.getBBox();
            labels[index] = {
              index,
              angle: +d.dataset.angle,
              r: +d.dataset.r,
              sx: +d.dataset.sx,
              sy: +d.dataset.sy,
              box,
              text: textEle.innerHTML,
              ele: d,
              layerIndex: d.dataset.layerIndex,
            };
          });

        collidingGroups = getCollidingGroups(labels);
        // 針對每組群集，找出最中間的標籤，並將其他標籤往外移
        collidingGroups.forEach((g) => {
          let group = g;
          const angles = g.map((d) => d.angle);
          const isCrossingZero = checkIsCrossZero(angles);
          if (isCrossingZero) {
            group = g.map((d) => {
              if (d.angle > 180) {
                return {
                  ...d,
                  angle: d.angle - 360,
                };
              }
              return d;
            });
          }

          const midAngle =
            group.reduce((acc, curr) => acc + curr.angle, 0) / group.length;
          // 將標籤依照距離中間的角度，由小到大排序
          const sorted = sortBy(group, (d) => Math.abs(d.angle - midAngle));
          const angleOffsets = {
            "-1": 0,
            1: 0,
          };
          const onlyTwo = sorted.length === 2;
          const setAngle = (d) => {
            const angleDiff = Math.sign(d.angle - midAngle);
            const newAngle = d.angle + angleOffsets[angleDiff];
            d.ele.dataset.angle = newAngle;
          };
          const moveAngGetBBox = (d) => {
            const angleDiff = Math.sign(d.angle - midAngle);

            angleOffsets[angleDiff] += angleDiff * 1 * (onlyTwo ? 0.5 : 1);
            const newAngle = d.angle + angleOffsets[angleDiff];
            const textEle = updateLabel(d, newAngle);
            const newBBox = textEle.getBBox();
            return newBBox;
          };
          // 最接近中間的標籤，不用移動
          sorted.slice(1).forEach((d, i) => {
            let angTries = 0;
            let isOverlapped = true;

            // 若重疊，則繼續迴圈
            while (isOverlapped && angTries < 30) {
              angTries++;

              const newBBox = moveAngGetBBox(d);

              // 若只有兩個標籤，則同時移動
              if (onlyTwo) {
                const otherBox = moveAngGetBBox(sorted[0]);
                isOverlapped = checkIsOverlapped(newBBox, otherBox);
                if (!isOverlapped) {
                  setAngle(d);
                  setAngle(sorted[0]);
                }
              } else {
                // 檢查是否與已檢查的標籤重疊
                isOverlapped = sorted
                  .slice(0, 1 + i)
                  .some((d) => checkIsOverlapped(newBBox, d.box));
                if (!isOverlapped) {
                  setAngle(d);
                }
              }
            }
          });
        });
        // 若還是有重疊，則繼續迴圈
      } while (collidingGroups.length && tries++ < 50);

      const groupedByLayer = groupBy(labels, "layerIndex");
      // 檢查標籤的新排序是否正確，若有交錯，則需調整位置
      Object.values(groupedByLayer).forEach((layer, l) => {
        // if (l === 0) return;
        const isCrossingZero = checkIsCrossZero(layer.map((d) => d.angle));
        const newLayer = isCrossingZero
          ? layer.map((d) => ({
              ...d,
              angle: d.angle > 210 ? d.angle - 360 : d.angle,
            }))
          : layer;

        const groups = groupBy(newLayer, (d) => Math.sign(d.angle));

        Object.values(groups).forEach((group) => {
          const sortedAngles = sortBy(group, "angle");

          group.forEach((d, i) => {
            const target = sortedAngles[i];
            if (d.index !== target.index) {
              const angleDiff = Math.abs(d.angle - target.angle);
              if (angleDiff < 30) {
                updateLabel(d, target.angle);
              }
            }
          });
        });
      });

      labels.forEach((d) => {
        d.box = d.ele.querySelector("text").getBBox();
      });
      let finalTries = 0;
      let finalCollisions;
      do {
        finalCollisions = getCollidingGroups(labels, 0.66);
        finalCollisions.forEach((g) => {
          const sortedByY = sortBy(g, "box.y");
          const base = sortedByY[sortedByY.length - 1];
          sortedByY
            .slice(0, -1)
            .reverse()
            .forEach((d, i, others) => {
              const prev = i ? others[i - 1] : base;
              const offsetY = prev.box.y + prev.box.height * 0.25 - d.box.y;
              translateLabel(d, 0, -offsetY);
            });
        });
      } while (finalCollisions.length && finalTries++ < 10);

      // 檢查完畢，顯示標籤
      labels.forEach((d) => {
        d.ele.setAttribute("opacity", 1);
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      // just in case
      containerRef.current?.container
        ?.querySelectorAll(".outer-label")
        .forEach((ele) => {
          ele.setAttribute("opacity", 1);
        });
    }, 3000);
  }, [JSON.stringify(modfyPieData)]);
  return (
    <StyleBox>
      <PieChart
        width={chartWidth}
        height={chartHeight}
        style={{ outline: "none" }}
        ref={containerRef}
      >
        <text
          x={chartWidth / 2}
          y={chartHeight / 2 - 10}
          dy={8}
          textAnchor="middle"
          fill="#005678"
        >
          合計
        </text>
        <text
          x={chartWidth / 2}
          y={chartHeight / 2 + 10}
          dy={8}
          textAnchor="middle"
          fill="#005678"
        >
          {total?.toLocaleString()}
        </text>
        <Tooltip content={<CustomTooltip total={total} />} />
        {modfyPieData.map(({ Label, ...d }, i) => {
          if (layerToggle && i && i > activeSlice.length) return null;
          const isInnerLayer = layerToggle && i;
          const layerData = isInnerLayer
            ? d.data.map((data) => ({
                ...data,
                fill:
                  data.parent?.index === activeSlice[i - 1]
                    ? data.fill
                    : "#fff",
              }))
            : d.data;

          return (
            <Pie
              startAngle={-270}
              data={layerData}
              dataKey={dataKey}
              cx="50%"
              cy="50%"
              innerRadius={d.innerRadius}
              outerRadius={d.outerRadius}
              label={
                <Label
                  total={total}
                  layerIndex={i}
                  shouldHidden={i < activeSlice.length}
                />
              }
              labelLine={false}
              key={i}
              animationDuration={inited ? 0 : 1000}
              isAnimationActive={i === 0 || !layerToggle}
              onAnimationEnd={() => {
                if (i === 0) {
                  setTimeout(onCalcOverlap);
                }
              }}
            >
              {layerData?.map((entry, index) => {
                const fill = entry.fill === "#fff" ? "transparent" : entry.fill;
                const isHidden = fill === "transparent";
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={fill}
                    stroke={isHidden ? fill : "#fff"}
                    style={{ outline: "none" }}
                    cursor={layerToggle && !isHidden ? "pointer" : "default"}
                    onClick={() => {
                      if (layerToggle && !isHidden && pieData[i + 1]) {
                        setActiveSlice((currenSlice) => {
                          const clone = currenSlice.slice(0, i + 1);
                          if (clone[i] === index) {
                            return clone.slice(0, i);
                          }
                          clone[i] = index;
                          return clone;
                        });
                      }
                    }}
                  />
                );
              })}
            </Pie>
          );
        })}
      </PieChart>
    </StyleBox>
  );
};

export default PieChartModule;
