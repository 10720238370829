import { Select } from "@chakra-ui/react";
import isNumber from "lodash/isNumber";
import React from "react";
import Box from "../components/Box";
import Arrow from "../components/Arrow";
import { isObject } from "lodash";

const TypeToggler = ({
  value,
  wdith,
  origin,
  onChange,
  options,
  filterCities,
  ...props
}) => {
  return (
    <Box.Relative className="select-wrapper">
      <Select
        width={wdith || "9.75em"}
        iconSize="0"
        value={Boolean(value) ? (filterCities ? value?.[2] + 1 : value) : 0}
        bg="white"
        borderRadius={"0"}
        border="none"
        onChange={(e) => {
          if (filterCities) {
            let clicked;
            if (+e.target.value) {
              clicked = filterCities[+e.target.value - 1].id;
            }
            onChange(Boolean(+e.target.value) ? clicked : null);
          } else {
            onChange(origin ? e.target.value : +e.target.value);
          }
        }}
        {...props}
      >
        {options &&
          options.map((type, i) =>
            isObject(type) ? (
              <option value={type.key} key={type.key}>
                {type.label}
              </option>
            ) : (
              <option value={origin ? type : i} key={type}>
                {isNumber(type) ? `${type - 1911}年` : type}
              </option>
            )
          )}
      </Select>
      <Arrow />
    </Box.Relative>
  );
};

export default TypeToggler;
