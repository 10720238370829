import React, { useMemo, useState, useEffect } from 'react'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import set from 'lodash/set'

import Box from '../../components/Box'

import useGetData from '../../contexts/useGetData'

import UpSection from './UpSection'
import useYears from '../useYears'
import DownSection from './DownSection'
import percent from '../../utils/percent'

const total = (data) => data.reduce((res, d) => {
  res.bup = percent((res.總預算 + d.value)/ (res.total + d.value + d.adjustment), true)
  res.adp = percent((res.追加減預算 + d.adjustment)/ (res.total + d.value + d.adjustment), true)
  res.總預算 = res.總預算 + d.value
  res.追加減預算 = res.追加減預算 + d.adjustment
  res.total = res.total + d.value + d.adjustment
  res.year = d.year
  return res
}, { 總預算: 0, 追加減預算: 0, total: 0 })

const filter = (data, city) => data ? data.filter(d => {
  if (city) {
    return d.city == city[1]
  } else {
    return d
  }
}) : null

const transformData = (data) => typeof data === "undefined" ? null : data?.reduce((res, d, i) => {
  if(d?.data.length) {
    d?.data.forEach((v) => {
      set(res, i, { ...d, value: (res?.[i]?.value || 0) + +v.value, adjustment: (res?.[i]?.adjustment || 0) + +v.adjustment, total: (res?.[i]?.total || 0) + +v.value + +v.adjustment, year: d.year, name: d.city })
    })
  } else {
    set(res, i, { ...d, value: 0, adjustment: 0, year: d.year, name: d.city, total: 0 })
  }
  return res
}, [])

const Organization = ({ filterCities, category, type, typeLabel, year, city, cities, setCity, budgetCategory }) => {
  const years = useYears()
  const [compare, setCompare] = useState(0)
  const [org, setOrg] = useState()
  const [recentYear, handleYear] = useState(+year)
  const { intactData: now } = useGetData(cities, category, type, year, 'budget')
  const { intactData: upnow } = useGetData(cities, category, type, recentYear, 'budget')
  const { intactData: ed } = useGetData(cities, category, type, recentYear - 1, 'budget')
  const { intactData: eded } = useGetData(cities, category, type, recentYear - 2, 'budget')
  const { intactData: ededed } = useGetData(cities, category, type, recentYear - 3, 'budget')
  const { intactData: edededed } = useGetData(cities, category, type, recentYear - 4, 'budget')
  useEffect(() => {
    setOrg(null)
  }, [compare])
  useEffect(() => {
    handleYear(+year)
    setOrg(null)
  }, [year])
  const allCityTotalBudget = useMemo(() => now && transformData(now), [now])
  const filterCityTotalBudget = useMemo(() => allCityTotalBudget && filter(allCityTotalBudget, city), [city, allCityTotalBudget])
  const totalBudget = useMemo(() => filterCityTotalBudget && total(filterCityTotalBudget), [filterCityTotalBudget])
  const recentData = useMemo(() => (edededed || ededed || eded || ed || upnow)
    && [
      filter(transformData(edededed), city),
      filter(transformData(ededed), city),
      filter(transformData(eded), city),
      filter(transformData(ed), city),
      filter(transformData(upnow), city)
    ].map((d, i, { length }) => {
      const theYear = recentYear - (length - 1 - i)
      if (years?.includes(theYear) && d) return total(d)
      return { 總預算: 0, 追加減預算: 0, total: 0, year: theYear }
    }), [edededed, ededed, eded, ed, upnow, city, recentYear, years])
  const orgData = useMemo(() => allCityTotalBudget && allCityTotalBudget.reduce((res, d) => {
    const orgfilter = d.data.filter(o => {
      if (org) {
        return o.name === org?.[0]
      } else return d
    })
    res.push({ name: d.name, value: +orgfilter?.[0]?.value || 0, adjustment: +orgfilter?.[0]?.adjustment || 0, total: (+orgfilter?.[0]?.value || 0) + (+orgfilter?.[0]?.adjustment || 0) })
    return res
  }, []), [allCityTotalBudget, org])
  const allOrgTotal = useMemo(() => filterCityTotalBudget && filterCityTotalBudget?.reduce((res, d) => {
    if(res.length) {
      d?.data.forEach(o => {
        const index = findIndex(res, { id: o.id })
        if(index > -1) {
          set(res, index, { ...res[index], value: +res[index].value + +o.value, adjustment: +res[index].adjustment + +o.adjustment, total: +res[index].total + +o.value + +o.adjustment })
        } else {
          res.push({ ...o, value: +o.value, adjustment: +o.adjustment, total: +o.value + +o.adjustment })
        }
      })
    } else {
      res = [...d.data.map(dd => ({ ...dd, value: +dd.value, adjustment: +dd.adjustment, total: +dd.value + +dd.adjustment }))]
    }
    return res
  }, []), [filterCityTotalBudget])
  const readyArr = useMemo(() => [edededed, ededed, eded, ed, upnow].map((d, i) => {
    if(includes(years, recentYear - ([edededed, ededed, eded, ed, upnow].length - 1 - i))) return d
    else return null
  }), [edededed, ededed, eded, ed, upnow, years, recentYear])
  const ready = useMemo(() => now && filterCities?.length == now?.length, [now, filterCities])
  const UpChartReady = useMemo(() => readyArr?.every((d, i) => {
    if(includes(years, recentYear - (readyArr.length - 1 - i))) return d
    else return !Boolean(d)
  }) ,[readyArr, recentYear, years])
  // console.log(allCityTotalBudget)
  return (
    <Box bg="white" borderRadius={'0 0 2em 2em'}>
      <UpSection
        ready={UpChartReady}
        data={recentData}
        year={recentYear}
        years={years}
        setYear={handleYear}
        category={category}
        typeLabel={typeLabel}
        city={city}
        setCity={setCity}
        filterCities={filterCities}
        budgetCategory={budgetCategory}
      />
      <DownSection
        compare={compare}
        data={now}
        city={city}
        category={category}
        typeLabel={typeLabel}
        setCompare={setCompare}
        setOrg={setOrg}
        setCity={setCity}
        org={org}
        ready={ready}
        totalBudget={totalBudget}
        allOrgTotal={allOrgTotal}
        orgData={orgData}
        allCityTotalBudget={allCityTotalBudget}
        year={year}
        budgetCategory={budgetCategory}
      />
    </Box>
  )
}

export default Organization
