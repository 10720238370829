import React from "react";
import { Box, Flex } from "@chakra-ui/react";

import TypeToggler from "./TypeToggler";
import useYears from "./useYears";

const budgetTypes = ["歲入", "歲出"];
const budgetCategories = [
  { label: "總預算", key: "value" },
  { label: "總預算(含追加減)", key: "final" },
];
// , { label: '追加減', key: 'adjustment' }
const Header = ({
  type,
  typeLabel,
  onTypeChange,
  typeOptions,
  category,
  categories,
  onCategoryChange,
  year,
  onYearChange,
  setCity,
  city,
  cities,
  filterCities,
  tabIndex,
  budgetType,
  onBudgetTypeChange,
  budgetCategory,
  onBudgetCategoryChange,
  ...props
}) => {
  const years = useYears();
  return (
    <Flex
      alignItems="flex-end"
      borderTop="4px solid"
      borderBottom="4px solid"
      borderColor="custom.strangeBlue"
      py="1.375em"
      px="2.75em"
      bg="linear-gradient(#f7f8f8, #dde8ed)"
      {...props}
    >
      <Flex flex={1} justifyContent="flex-end" alignItems="center">
        <Box
          mx="1.5em"
          border="1px solid white"
          borderRadius={"full"}
          overflow="hidden"
        >
          <TypeToggler
            width="7.75em"
            origin
            value={year}
            options={years}
            onChange={onYearChange}
          />
        </Box>
        {tabIndex == 1 && (
          <Box
            mr="1.5em"
            width="7em"
            border="1px solid white"
            borderRadius={"full"}
            overflow="hidden"
          >
            <TypeToggler
              value={budgetType}
              options={budgetTypes}
              onChange={onBudgetTypeChange}
            />
          </Box>
        )}
        {(tabIndex == 3 || tabIndex == 2) && (
          <Box
            mr="1.5em"
            border="1px solid white"
            borderRadius={"full"}
            overflow="hidden"
          >
            <TypeToggler
              width="12em"
              origin
              value={budgetCategory}
              options={budgetCategories}
              onChange={onBudgetCategoryChange}
            />
          </Box>
        )}
        <Flex borderRadius={"full"} border="1px solid white" overflow="hidden">
          <TypeToggler
            value={type}
            options={typeOptions}
            onChange={onTypeChange}
          />
          <TypeToggler
            value={category}
            origin
            options={categories}
            onChange={onCategoryChange}
          />
          <TypeToggler
            value={city}
            filterCities={filterCities}
            options={cities}
            onChange={setCity}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
