import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Center,
  HStack,
  Spinner,
  AccordionButton,
  AccordionPanel,
  AccordionItem
} from '@chakra-ui/react'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Title from '../../components/Title'
import OrgSelect from './OrgSelect'
import DownloadButton from '../DownloadButton'
import BarChartModule from '../Charts/BarChart'
import UnitText from '../../components/UnitText'
import DebounceRenderChart from '../../components/DebounceRenderChart'

const BudgetAnalytics = ({
    city,
    category,
    typeLabel,
    totalBudget,
    allTotalBudget,
    year,
  }) => {
  const bottomRef = useRef()
  const [ready, setReady] = useState()
  useEffect(() => {
    setReady(false)
    setTimeout(() => {
      setReady(true)
    }, 500)
  } ,[year])
  // console.log(totalBudget, allTotalBudget)
  const data = useMemo(() => city ? totalBudget : allTotalBudget ,[city, totalBudget, allTotalBudget])
  return (
    <AccordionItem border={'none'} pt="1em" pb="2.25em" ref={bottomRef}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _hover={{}}
            _active={{}}
            _focus={{}}
            p="0"
          >
            <Title
              width="100%"
              city={city}
              category={category}
              typeLabel={typeLabel}
              title="預算員額分析（經資併計）"
              isExpanded={isExpanded}
              // note={<UnitText ml="0.25rem" fontSize="0.875rem" letterSpacing="0em" />}
              titleRight={(
                <HStack>
                  <UnitText ml="0.25rem" fontSize="0.875rem" letterSpacing="0em">人</UnitText>
                  <DownloadButton title="預算員額分析（經資併計）" domRef={bottomRef} />
                </HStack>
              )}
            />
          </AccordionButton>
          <AccordionPanel>
            <Box px="1em" pt="2.875em">
              {ready ? (data?.length) ? (
                <DebounceRenderChart data={data}>
                  <BarChartModule
                    width={1016}
                    left={60}
                    data={data}
                  />
                </DebounceRenderChart>
              ) : <Center height={300}><Text>未有資料</Text></Center> : <Center height={300}><Spinner /></Center>}
            </Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export default BudgetAnalytics
