import React, { useState, useEffect, useMemo } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Tooltip,
} from "recharts";
import { nanoid } from "nanoid";
import styled from "@emotion/styled";

import Box from "../../components/Box";
import { Flex, Text } from "@chakra-ui/react";
import sortBy from "lodash/sortBy";
import getChineseNumber from "../../utils/getChineseNumber";
import useYears from "../useYears";

const StyledBox = styled(Box)`
  .recharts-surface {
    pointer-events: none;
  }
`;

const legend = {
  cp: "資本門",
  ap: "經常門",
};

const CustomTooltip = ({ active, payload }) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0]?.payload?.fill !== "transparent"
  ) {
    // console.log(payload)
    return (
      <Box
        borderRadius={"1em"}
        bg="white"
        boxShadow={"1px 1px 3px"}
        px="0.75em"
        py="0.375em"
        overflow="hidden"
      >
        <Text fontSize={"1.3125em"} color={"#585757"}>
          {payload[0].payload.year}年 {payload[0].payload.name}
        </Text>
        {sortBy(payload, (o) => o.value)
          .reverse()
          .map((d, i) => (
            <Box key={d.name}>
              <Flex fontSize={"1.3125em"} color={d.color}>
                {legend[d.name] && (
                  <Text as="span" color="#585757" mr="0.5rem">
                    {legend[d.name]}
                  </Text>
                )}
                <Box flex={1} textAlign="right">
                  {d.value.toLocaleString()}
                </Box>
              </Flex>
            </Box>
          ))}
      </Box>
    );
  }

  return null;
};

const LineChartModule = ({
  data,
  dataKey,
  keyColor,
  dataKeyII,
  keyIIColor,
  keyDotColor,
  width,
  left = 0,
  right = 0,
  height,
  showNumber,
  xpl,
  budgetType,
}) => {
  const chartRef = React.useRef();
  const gradientId = useMemo(() => nanoid(), []);
  const years = useYears();

  return (
    <StyledBox mx="1.125em" mb="1.5em">
      <Box
        mb="0.875rem"
        color="white"
        borderRadius={"full"}
        bg="linear-gradient(90deg, #00729f, #54b9bf)"
        py="0.375rem"
        px={"1rem"}
        fontSize={"1.125em"}
      >
        {data?.[0].name ||
          `近${getChineseNumber(years.length)}年總體${
            budgetType ? "歲出政事" : "歲入來源"
          }`}
      </Box>
      <LineChart
        ref={chartRef}
        width={width}
        height={height || 198}
        data={data}
        // onMouseEnter={onHover}
        // onMouseLeave={onLeave}
        margin={{
          top: 5,
          right,
          bottom: 5,
          left,
        }}
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
            spreadMethod="pad"
          >
            <stop offset="0%" stopColor="#f7f8f8" stopOpacity="1" />
            <stop offset="100%" stopColor="#dde8ed" stopOpacity="1" />
          </linearGradient>
        </defs>
        <CartesianGrid
          fill={`url(#${gradientId})`}
          stroke="#fff"
          vertical={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <XAxis
          dataKey="year"
          tick={{ fill: "#b4b4b5" }}
          padding={{ left: xpl || 30, right: 45 }}
        >
          <Label
            fill="#b4b4b5"
            value="(年)"
            dy={-6}
            dx={5}
            position="insideBottomRight"
          />
        </XAxis>
        <YAxis
          fill="#b4b4b5"
          tick={{ fill: "#b4b4b5", fontSize: 10 }}
          padding={{ top: 40 }}
          tickFormatter={(value) => Number(value).toLocaleString()}
        >
          <Label
            fill="#b4b4b5"
            value="(新臺幣千元)"
            position="insideTopLeft"
            dx={-18}
            fontSize={12}
          />
        </YAxis>
        <Line
          id="line1"
          dataKey={dataKey || "value"}
          stroke={keyColor || "#5798C5"}
          strokeWidth={2}
          dot={{
            stroke: keyDotColor || "#005678",
            fill: keyDotColor || "#005678",
          }}
          activeDot={{
            stroke: keyDotColor || "#005678",
            fill: keyDotColor || "#005678",
          }}
          // label={showNumber ? <CustomizedLabel labelFontSize={18} />
          //   : (isHover ? <CustomizedLabel height={height || 198} labelPosition={hasValueDiff} positions={hasValueDiff && valueDiff} data={data} labelColor={keyDotColor || '#005678'} /> : null)}
        />
        <Line
          id="line1"
          dataKey={dataKeyII}
          stroke={keyIIColor || "#5798C5"}
          strokeWidth={2}
          dot={{ stroke: "#005678", fill: "#005678" }}
          activeDot={{ stroke: "#005678", fill: "#005678" }}
          // label={showNumber ? <CustomizedLabel labelFontSize={18} />
          //   : (isHover ? <CustomizedLabel height={height || 198} labelPosition={hasValueDiff} positions={hasValueDiff && valueDiff.map(d => d * -1)} data={data} labelColor="#005678" /> : null)}
        />
      </LineChart>
    </StyledBox>
  );
};

export default LineChartModule;
