import { Center, forwardRef, Spinner } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import includes from "lodash/includes";
import last from "lodash/last";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Title from "../../components/Title";
import useGetData from "../../contexts/useGetData";
import LineChartModule from "../Charts/LineChart";
import DownloadButton from "../DownloadButton";
import useYears from "../useYears";
import DebounceRenderChart from "../../components/DebounceRenderChart";
import getChineseNumber from "../../utils/getChineseNumber";

const RenderLineChart = ({
  data,
  left,
  right,
  width,
  showNumber,
  xpl,
  budgetType,
  yearLength,
  ...props
}) => {
  return (
    <DebounceRenderChart data={data}>
      <LineChartModule
        showNumber={showNumber}
        xpl={xpl}
        left={left}
        right={right}
        width={width}
        data={data.slice(-yearLength)}
        budgetType={budgetType}
        {...props}
        // legends={legends}
      />
    </DebounceRenderChart>
  );
};

const CompareCharts = ({
  ready,
  city,
  budgetData,
  budgetType,
  year,
  yearLength,
  // show,
  // yearIndex,
}) => {
  const data = useMemo(
    () =>
      city
        ? budgetData?.map((d) => d.filter((dd) => dd?.city === city[1]))
        : budgetData,
    [budgetData, city]
  );
  const allYearDatas = useMemo(
    () =>
      data &&
      data?.reduce((res, d) => {
        const total = d?.reduce((value, v) => {
          const answer = v.data.reduce((a, number) => {
            a = a + +number.value;
            return a;
          }, 0);
          value = value + answer;
          return value;
        }, 0);
        res.push({ year: +d?.[0]?.year - 1911, value: total });
        return res;
      }, []),
    [data]
  );
  const detailYearDatas = useMemo(() => {
    if (!data) return [];
    const detailData = data.reduce((allTypes, yearData) => {
      const details = yearData?.reduce((value, cityData) => {
        cityData.data.forEach((dd) => {
          if (value[dd.id]) {
            value[dd.id].value = +value[dd.id].value + +dd.value;
          } else {
            value[dd.id] = {
              ...dd,
              year: +cityData.year - 1911,
              value: +dd.value,
            };
          }
        });
        return value;
      }, {});

      Object.values(details)?.forEach((ddd) => {
        allTypes[ddd.id] = allTypes[ddd.id] || {};
        allTypes[ddd.id][ddd.year] = ddd;
      });
      return allTypes;
    }, {});
    return Object.values(detailData).map((years) =>
      data.map((dd) => {
        const year = dd?.[0]?.year - 1911;
        const d = years[year];
        if (d) return d;
        return {
          ...Object.values(years)[0],
          value: 0,
          year,
        };
      })
    );
  }, [data, year]);
  // console.log(allYearDatas)
  return (
    <>
      <Box pt="2.5em">
        {ready && allYearDatas ? (
          <RenderLineChart
            showNumber={true}
            xpl={50}
            left={25}
            right={60}
            mr="1em"
            width={1160}
            height={240}
            data={allYearDatas}
            budgetType={budgetType}
            key={JSON.stringify(allYearDatas) + year}
            yearLength={yearLength}
          />
        ) : (
          <Center width={1160} height={240}>
            <Spinner />
          </Center>
        )}
      </Box>
      <Flex flexWrap={"wrap"}>
        {detailYearDatas &&
          detailYearDatas?.map((d, i) => (
            <Box width={1 / 3} key={i}>
              {ready ? (
                <RenderLineChart
                  left={15}
                  right={30}
                  width={370}
                  data={d}
                  key={JSON.stringify(d) + year}
                  yearLength={yearLength}
                />
              ) : (
                <Center width={370} height={198}>
                  <Spinner />
                </Center>
              )}
            </Box>
          ))}
      </Flex>
    </>
  );
};

const transformData = (data, years, year) =>
  data?.map((d, i, { length }) => {
    const theYear = year - (length - 1 - i);
    if (years?.includes(theYear) && d) return d;
    return last(data)?.map((d) => ({ ...d, year: theYear, data: [] }));
  });

const Compare = forwardRef(
  ({ cities, budgetType, category, type, typeLabel, year, city }) => {
    const years = useYears();
    const dataType = budgetType ? "spending" : "revenue";
    const { intactData: ed } = useGetData(
      cities,
      category,
      type,
      year - 1,
      dataType
    );
    const { intactData: eded } = useGetData(
      cities,
      category,
      type,
      year - 2,
      dataType
    );
    const { intactData: ededed } = useGetData(
      cities,
      category,
      type,
      year - 3,
      dataType
    );
    const { intactData: edededed } = useGetData(
      cities,
      category,
      type,
      year - 4,
      dataType
    );
    const { intactData: now } = useGetData(
      cities,
      category,
      type,
      year,
      dataType
    );
    const all = useMemo(
      () =>
        (edededed || ededed || eded || ed || now) &&
        transformData([edededed, ededed, eded, ed, now], years, year),
      [years, year, edededed, ededed, eded, ed, now]
    );
    const readytArr = useMemo(
      () =>
        [edededed, ededed, eded, ed, now].map((d, i, { length }) => {
          if (includes(years, year - (length - 1 - i))) return d;
          else return null;
        }),
      [edededed, ededed, eded, ed, now, years, year]
    );
    const ready = useMemo(
      () =>
        readytArr?.every((d, i) => {
          if (includes(years, year - (readytArr.length - 1 - i))) return d;
          else return !d;
        }),
      [years, year, readytArr]
    );
    // const [show, setShow] = useState()
    // useEffect(() => {
    //   setShow(false)
    //   setTimeout(() => {
    //     setShow(true)
    //   }, 500)
    // } ,[year])
    // console.log(readytArr)
    const chartRef = useRef();
    return (
      <Box pt="3.25em" px="1em" ref={chartRef}>
        <Title
          city={city}
          category={category}
          typeLabel={typeLabel}
          budgetType={budgetType}
          px="1em"
          title={`${budgetType ? "政事" : "來源"}近${getChineseNumber(
            years.length
          )}年比較`}
          titleRight={
            <DownloadButton
              domRef={chartRef}
              title={`來源近${getChineseNumber(years.length)}年比較`}
            />
          }
        />
        <CompareCharts
          {...{
            city,
            budgetData: all,
            budgetType,
            ready,
            // show,
            year,
            yearLength: years.length,
          }}
        />
      </Box>
    );
  }
);

export default Compare;
