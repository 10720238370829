import React, { useEffect, useMemo, useRef, useState } from "react";
import { Center, HStack, Spinner } from "@chakra-ui/react";

import BarChart from "./UseForBarChart";
import Box from "../../components/Box";
import Text from "../../components/Text";
import Flex from "../../components/Flex";
import Title from "../../components/Title";
import OrgSelect from "./OrgSelect";
import DownloadButton from "../DownloadButton";
import UnitText from "../../components/UnitText";
import DebounceRenderChart from "../../components/DebounceRenderChart";

const legends = [
  { label: "經常門", bg: "#5798C5" },
  { label: "資本門", bg: "#9DD8D5" },
];

const RenderChart = ({ expense, city, onSetCity, setItem }) => {
  return (
    <Box px="1em" pt="1em">
      {expense.length ? (
        <DebounceRenderChart data={expense}>
          <BarChart
            width={1080}
            data={expense}
            city={city}
            onSetCity={onSetCity}
            setOrg={setItem}
            right={320}
            dataKey="ap"
            dataKeyII="cp"
            labelKey="rateAp"
            labelKeyII="rateCp"
            dataKeyIIColor="#9DD8D5"
            labelColor="#FFF"
          />
        </DebounceRenderChart>
      ) : (
        <Center height={300}>未有資料</Center>
      )}
    </Box>
  );
};

const BudgetUseForAnalytics = ({
  city,
  category,
  typeLabel,
  setItem,
  setCity,
  item,
  usedOptions,
  spendingDetail,
  year,
}) => {
  const bottomRef = useRef();
  const expense = useMemo(
    () =>
      spendingDetail.map((d) => ({
        ...d,
        cp: d.cp < 0 ? 0 : d.cp,
        ap: d.ap < 0 ? 0 : d.ap,
        originCp: d.cp < 0 && d.cp,
        originAp: d.ap < 0 && d.ap,
      })),
    [spendingDetail]
  );
  // console.log(expense)
  return (
    <Box pt="1em" pb="2.25em" ref={bottomRef}>
      <Title
        width="100%"
        city={city}
        category={category}
        typeLabel={typeLabel}
        isExpanded
        title="歲出用途別分析"
        note={<UnitText ml="0.25rem" fontSize="0.875rem" letterSpacing="0em" />}
        titleRight={
          <HStack>
            {usedOptions ? (
              <OrgSelect
                options={[{ name: "全部" }, ...usedOptions]}
                onChange={setItem}
                org={item}
              />
            ) : (
              <Spinner />
            )}
            <DownloadButton
              title="預算員額分析（經資併計）"
              domRef={bottomRef}
            />
          </HStack>
        }
      />
      <Box>
        <Flex gap={2} pt="1.5em" pr="1.25em" justifyContent={"flex-end"}>
          {legends.map(({ label, bg }) => (
            <Flex gap={2} alignItems={"center"} key={label}>
              <Box bg={bg} width="3.75em" height={"1em"} />
              <Text>{label}</Text>
            </Flex>
          ))}
        </Flex>
      </Box>
      <RenderChart
        expense={expense}
        year={year}
        city={city}
        onSetCity={setCity}
        setOrg={setItem}
        key={JSON.stringify(expense) + year}
      />
    </Box>
  );
};

export default BudgetUseForAnalytics;
