import React from "react";

import Flex from "./Flex";
import Box from "./Box";

const LargeToogle = ({ options, onChange, value }) => {
  return (
    <Flex
      position="relative"
      rounded="full"
      bg="white"
      border={"1px solid white"}
      boxShadow={"2px 2px 2px rgba(0, 0, 0, 0.2)"}
      width="20em"
      fontSize="1.125em"
      mr="0.125em"
    >
      {options.map((label, i) => (
        <Box
          width="100%"
          key={i}
          borderRadius="full"
          px="1.25rem"
          py="0.375rem"
          color={value === i ? "black" : "custom.gray"}
          fontWeight={500}
          position="relative"
          transition="all 500ms"
          letterSpacing="widest"
          textAlign="center"
          onClick={() => onChange(i)}
          cursor="pointer"
          overflow={"hidden"}
          _after={{
            content: '""',
            position: "absolute",
            display: "block",
            border: "1px solid white",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            filter: "drop-shadow(0px 0px 3px black)",
            borderRadius: "full",
            pointerEvents: "none",
          }}
        >
          {label}
        </Box>
      ))}
      <Box
        position="absolute"
        transition="all 500ms"
        bgGradient="linear(custom.orange, custom.yellow)"
        width="57%"
        height="130%"
        left="0"
        top="0"
        transform={`translate(${value ? 78 : 0}%, -15%)`}
        rounded="full"
        boxShadow="md"
        overflow={"hidden"}
        _after={{
          content: '""',
          position: "absolute",
          bgGradient: "linear(to-r, white, rgba(255, 255, 255, 0))",
          display: "block",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
          pointerEvents: "none",
          transform: "rotate(-30deg) translate(-45%, -125%)",
        }}
      >
        <Box.AbsCenter width="60%" textAlign="center">
          {options[value]}
        </Box.AbsCenter>
      </Box>
    </Flex>
  );
};

export default LargeToogle;
