import React, { useEffect, useRef, useState } from "react";
import { Center, HStack, Spinner } from "@chakra-ui/react";

import Box from "../../components/Box";
import Text from "../../components/Text";
import Title from "../../components/Title";
import OrgSelect from "./OrgSelect";
import DownloadButton from "../DownloadButton";
import BarChartModule from "../Charts/BarChart";
import UnitText from "../../components/UnitText";
import useExportConfig from "../../contexts/exportConfig/useExportConfig";
import LargeToogle from "../../components/LargeToogle";

const options = ["不同縣市比較", "不同機關比較"];

const DownSection = ({
  compare: parentCompare,
  city,
  category,
  typeLabel,
  setCompare,
  setOrg,
  setCity,
  org,
  ready,
  totalBudget,
  allOrgTotal,
  orgData,
  allCityTotalBudget,
  year,
  budgetCategory,
}) => {
  const exportConfig = useExportConfig();
  const bottomRef = useRef();
  const compareLocked = Boolean(exportConfig.city);
  const compare = +(parentCompare || compareLocked);
  const [iter, setIter] = useState(0);
  useEffect(() => {
    setIter((i) => i + 1);
  }, [year]);
  // console.log(allCityTotalBudget)
  return (
    <Box pt="3.75em" pb="4em" px="3.5em" ref={bottomRef}>
      <Title
        city={city}
        category={category}
        typeLabel={typeLabel}
        title="歲出預算分析"
        note={<UnitText ml="0.25rem" fontSize="0.875rem" letterSpacing="0em" />}
        titleRight={
          <HStack>
            <LargeToogle
              options={options}
              onChange={!compareLocked && setCompare}
              value={compare}
            />
            {ready ? (
              <OrgSelect
                options={[{ name: "全部" }, ...allOrgTotal]}
                onChange={setOrg}
                org={org}
              />
            ) : (
              <Spinner />
            )}
            <DownloadButton title="總預算分析" domRef={bottomRef} />
          </HStack>
        }
      />
      {Boolean(compare) && (
        <Text
          lineHeight={"1"}
          fontWeight="bold"
          ml="2.375rem"
          mt="2.375rem"
          fontSize={"3.125em"}
          color="custom.textBlue"
        >
          {allOrgTotal?.length ? (
            Number(
              totalBudget[budgetCategory === "final" ? "total" : "總預算"]
            ).toLocaleString()
          ) : (
            <Text fontSize={"1.5rem"}>未有資料</Text>
          )}
        </Text>
      )}
      <Box px="4em" pt="2.875em">
        {ready ? (
          <BarChartModule
            compare={compare}
            right={!compare && org ? 480 : 0}
            data={compare ? allOrgTotal : org ? orgData : allCityTotalBudget}
            city={city}
            org={org}
            setOrg={setOrg}
            onSetCity={setCity}
            key={iter}
            dataKey={budgetCategory === "final" ? "total" : "value"}
          />
        ) : (
          <Center width={880} height={300}>
            <Spinner />
          </Center>
        )}
      </Box>
    </Box>
  );
};

export default DownSection;
