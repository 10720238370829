import React, { useEffect, useState, useMemo } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import useSWR from "swr";

import Container from "../components/Container";
import UpdatedAt from "./UpdatedAt";

import Header from "./Header";
import Budget from "./Budget";
import Analytics from "./Analytics";
import Organization from "./Organization";
import Application from "./Application";
import useYears from "./useYears";
import useExportConfig from "../contexts/exportConfig/useExportConfig";

const types = ["市縣", "鄉鎮市"];

const categories = ["全部市縣", "縣市", "直轄市"];
const allTabs = [
  { Comp: Budget, title: "總預算分析" },
  { Comp: Analytics, title: "歲入歲出科目分析" },
  { Comp: Organization, title: "歲出機關別分析" },
  { Comp: Application, title: "歲出用途別分析" },
].map((d, index) => ({ ...d, index }));

const HomePage = () => {
  const exportConfig = useExportConfig();
  const tabs = useMemo(() => {
    if (exportConfig.tabs?.length) {
      return allTabs.filter((d, i) => exportConfig.tabs.includes(i));
    }
    return allTabs;
  }, [exportConfig]);

  const [tabIndex, handleTabsChange] = useState(0);
  const [isSub, setIsSub] = useState(0);
  const [category, setCategory] = useState("全部市縣");
  const [budgetType, setBudgetType] = useState(0);
  const [budgetCategory, setBudgetCategory] = useState("value");
  const [year, setYear] = useState(() => new Date().getFullYear() - 0);
  const [cityCode, setCity] = useState(exportConfig.city);
  const years = useYears();
  const type = isSub ? "b" : "a";
  const typeLabel = types[+isSub];
  const onSetCity = (newCity) => !exportConfig.city && setCity(newCity);

  useEffect(() => {
    setCity(exportConfig.city);
    if (isSub) {
      setCategory("全部市縣");
    }
  }, [isSub, category]);
  useEffect(() => {
    if (years.length) {
      setYear((y) => Math.min(+years[0], y));
    }
  }, [years]);
  const { data: rawCities } = useSWR(`/data/${type}/index.json`);
  const cities = useMemo(
    () =>
      exportConfig.city
        ? rawCities?.filter((d) => d.id === exportConfig.city)
        : rawCities,
    [rawCities]
  );
  const cityOptions = useMemo(
    () =>
      cities &&
      cities.filter((d) => {
        if (category == "全部市縣") {
          return d;
        } else {
          return d.type === category;
        }
      }),
    [cities, category]
  );
  const city = useMemo(() => {
    if (!cityCode || !cityOptions) return null;
    const cityIndex = cityOptions.findIndex((d) => d.id === cityCode);
    const pickedCity = cityOptions[cityIndex];
    if (!pickedCity) return null;
    return [cityCode, pickedCity.name, cityIndex, pickedCity.type];
  }, [cityCode, cityOptions]);
  return (
    <Container
      id="content"
      position={"relative"}
      minWidth={"78em"}
      maxWidth={"78em"}
      pt="3.75em"
      pb="3.75em"
      overflow={"visible"}
    >
      <Tabs
        position={"relative"}
        index={tabIndex}
        onChange={handleTabsChange}
        isLazy
      >
        <UpdatedAt />
        <TabList border="none">
          {tabs.map(({ title: tab }) => (
            <Tab
              fontSize={"2em"}
              color="custom.tabTextGray"
              borderRadius={"1em 1em 0 0"}
              bg="custom.tabBgGray"
              width={1 / 3}
              pt="1.167rem"
              pb="0.75rem"
              key={tab}
              position="relative"
              overflow="hidden"
              _selected={{
                color: "black",
                bgGradient: "linear(to-r, #FF8C23, #FFC21F)",
                _after: {
                  content: '""',
                  position: "absolute",
                  display: "block",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  opacity: 0.25,
                  pointerEvents: "none",
                  bgGradient:
                    "linear(to-b, rgba(255,255,255,1), rgba(255,255,255,0))",
                  transform: "rotate(27deg)",
                  transformOrigin: "0 0",
                  mixBlendMode: "color-dodge",
                },
              }}
            >
              {tab}
            </Tab>
          ))}
        </TabList>
        <Header
          typeLabel={typeLabel}
          type={isSub}
          onTypeChange={setIsSub}
          typeOptions={types}
          category={category}
          categories={isSub ? [categories[0]] : categories}
          onCategoryChange={setCategory}
          year={year}
          onYearChange={setYear}
          setCity={onSetCity}
          city={city}
          cities={
            cities &&
            ["全部", ...cityOptions.map((d) => d.name)].slice(
              cityOptions.length > 1 ? 0 : 1
            )
          }
          filterCities={cityOptions}
          onBudgetTypeChange={setBudgetType}
          onBudgetCategoryChange={setBudgetCategory}
          budgetType={budgetType}
          budgetCategory={budgetCategory}
          tabIndex={tabs[tabIndex].index}
        />
        <TabPanels position={"relative"}>
          {tabs.map(({ Comp }, k) => (
            <TabPanel p="0" key={k}>
              {cities && (
                <Comp
                  cities={cities}
                  filterCities={cityOptions}
                  type={type}
                  typeLabel={typeLabel}
                  year={year}
                  city={city}
                  setCity={onSetCity}
                  category={category}
                  isSub={isSub}
                  budgetType={budgetType}
                  budgetCategory={budgetCategory}
                  setYear={setYear}
                  key={`${type}${category}${budgetType}`}
                />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default HomePage;
