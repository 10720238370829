import React, { useMemo, useRef } from "react";
import { HStack, Flex, Select, Text } from "@chakra-ui/react";
import sumBy from "lodash/sumBy";
import sortBy from "lodash/sortBy";
import Box from "../../components/Box";
import Title from "../../components/Title";
import UnitText from "../../components/UnitText";
import DownloadButton from "../DownloadButton";
import RenderPieChart from "./RenderPieChart";
import LargeToogle from "../../components/LargeToogle";

const useFor = ["一級用途別分析", "二級用途別分析"];
const lengeds = [
  { bg: "#5798C5", label: "經常門" },
  { bg: "#6ECBC6", label: "資本門" },
];
const options = ["經資分列", "經資併計"];
const fills = [
  "#71B4CC",
  "#6ECBC6",
  "#5798C5",
  "#F7C331",
  "#E27A0A",
  "#E0470B",
];

const PieChartAnalytics = ({
  city,
  year,
  category,
  typeLabel,
  setCompare,
  compare,
  setLevel,
  level,
  data1,
  data2,
  data3,
  budgetCategory,
}) => {
  const bottomRef = useRef();
  const pieData = useMemo(() => {
    if (data1 && data2 && data3) {
      const transformData2 = sortBy(
        data2.reduce((res, d) => {
          const index = res.findIndex((r) => r.name === d.name);
          if (index > -1) {
            res[index][budgetCategory] =
              +res[index][budgetCategory] + +d[budgetCategory];
            if (d.children && !res[index].children) {
              res[index].children = d.children;
            }
          } else {
            const clonedD = JSON.parse(JSON.stringify(d));
            res.push(clonedD);
          }
          return res;
        }, []),
        (o) => o[budgetCategory]
      ).map((d, i) => ({ ...d, fill: fills[i] }));
      if (level) {
        if (compare) {
          return [
            transformData2,
            transformData2
              .map((d, i) => {
                if (d.children) {
                  const newC = sortBy(
                    d.children.map((dd) => ({
                      ...dd,
                      fill: fills[i],
                    })),
                    (o) => o[budgetCategory]
                  );
                  if (
                    sumBy(newC, (o) => +o[budgetCategory]) < +d[budgetCategory]
                  ) {
                    newC.push({
                      value:
                        +d[budgetCategory] -
                        sumBy(newC, (o) => +o[budgetCategory]),
                      fill: "transparent",
                    });
                  }
                  return newC;
                } else {
                  return { ...d, fill: "transparent" };
                }
              })
              .flat(),
          ];
        } else {
          return [data1, data2, data3];
        }
      } else {
        if (compare) {
          return [transformData2];
        } else {
          return [data1, data2];
        }
      }
    }
  }, [data1, data2, data3, level, compare, budgetCategory]);

  const total = useMemo(
    () =>
      data1 &&
      data1.reduce((res, d) => {
        res = res + d[budgetCategory];
        return res;
      }, 0),
    [data1, budgetCategory]
  );
  return (
    <Box border={"none"} pt="1em" ref={bottomRef}>
      <Title
        width="100%"
        city={city}
        category={category}
        typeLabel={typeLabel}
        isExpanded
        title="歲出用途別分析"
        titleRight={
          <HStack>
            <LargeToogle
              options={options}
              onChange={setCompare}
              value={compare}
            />
            <Select
              value={level}
              flex={1}
              onChange={(e) => setLevel(+e.target.value)}
              onClick={(e) => {
                if (e && e.stopPropagation) e.stopPropagation();
              }}
            >
              {useFor.map((d, i) => (
                <option value={i} key={i}>
                  {d}
                </option>
              ))}
            </Select>
            <DownloadButton
              title="預算員額分析（經資併計）"
              domRef={bottomRef}
            />
          </HStack>
        }
      />
      <Flex mt="0.625em" gap={2} justifyContent="flex-end">
        {lengeds.map(({ bg, label }) => (
          <Flex alignItems={"center"} key={label}>
            <Box bg={bg} height="1em" width={"1.5em"} />
            <Text ml="0.5rem" color="#727171">
              {label}
            </Text>
          </Flex>
        ))}
        <UnitText fontSize="1em" />
      </Flex>
      <RenderPieChart
        key={JSON.stringify(pieData)}
        pieData={pieData}
        total={total}
        dataKey={budgetCategory}
      />
    </Box>
  );
};

export default PieChartAnalytics;
