import { AspectRatio, Box, HStack, Text } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import React, { useRef } from "react";

import Card from "../components/Card";
import UnitText from "../components/UnitText";

import TreeMap from "./Charts/TreeMap";
import DownloadButton from "./DownloadButton";
import useLabel from "../api/useLabel";

const RevenueTree = ({ data, category, dataReady, typeLabel, city }) => {
  const label = useLabel("revenueTitle");
  const domRef = useRef();
  return (
    <Card
      ref={domRef}
      title={label}
      hightlight={
        city
          ? city[1]
          : (category != "全部市縣" ? `全${category}` : null) ||
            `全${typeLabel}`
      }
      titleRight={
        <HStack spacing={2}>
          <UnitText />
          <DownloadButton title={label} domRef={domRef} />
        </HStack>
      }
    >
      {dataReady ? (
        data?.length ? (
          <TreeMap data={data} colorScheme="green" valueColor="darkGreen" />
        ) : (
          <AspectRatio ratio={1}>
            <Text>未有資料</Text>
          </AspectRatio>
        )
      ) : (
        <Spinner colorScheme="green" />
      )}
    </Card>
  );
};

export default RevenueTree;
