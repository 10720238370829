import React from 'react'
import Text from './Text'

const UnitText = (props) => {
  return (
    <Text as="span" fontWeight="500" color="custom.darkGray" fontSize={'0.75rem'} {...props}>(單位：{props.children || '新臺幣千元'})</Text>
  )
}

export default UnitText
