import { Tooltip } from "@chakra-ui/react";
import { format } from "d3-format";
import React from "react";
import { BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";

const f = format(",d");
const parsePercent = (str) => str?.replace("%", "");

const barSize = 67;

const CustomizedLabel = ({ x, y, value, width }) => {
  return (
    Boolean(value) && (
      <g>
        <circle
          r={value.toString().length * 6}
          fill="none"
          cx={x + width / 2}
          cy={y - 100}
          stroke="#005678"
          stroke-width={2}
        />
        <Tooltip
          label="總預算 (含追加減)"
          bg="rgba(255, 255, 255, 0.9)"
          color="black"
          fontSize={"1.125em"}
          borderRadius="0.5rem"
          p="0.25rem 0.5rem"
        >
          <text
            x={x + width / 2}
            y={y + 6}
            dy={-100}
            fill={"#005678"}
            fontSize={16}
            textAnchor="middle"
          >
            {Number(value).toLocaleString()}
          </text>
        </Tooltip>
      </g>
    )
  );
};

const CustomizedLabelList = ({ x, y, value, width, height }) => {
  return (
    parsePercent(value) > 0 && (
      <g>
        <text
          x={x + width / 2}
          y={height > 20 ? y + 14 : y - 4}
          fill={height > 20 ? "#fff" : "#8fd9d5"}
          fontSize={16}
          textAnchor="middle"
        >
          {value}
        </text>
      </g>
    )
  );
};

const StackedBarChart = ({ data, yearLength, isFinal }) => {
  return (
    <BarChart
      width={646}
      height={540}
      data={data.slice(-yearLength)}
      margin={{
        top: 200,
        right: 0,
        left: -100,
        bottom: 65,
      }}
      overflow="visible"
    >
      <XAxis
        tickLine={false}
        tick={{ fill: "#000", dy: 10 }}
        dataKey="year"
        padding={{ left: 60, right: 20 }}
      />
      <YAxis tickLine={false} axisLine={false} tick={false} />
      <Bar barSize={barSize} dataKey="總預算" stackId="a" fill="#5798c5">
        {isFinal && (
          <>
            <LabelList dataKey="bup" fill="#fff" position="insideTop" />
            <LabelList
              dataKey="追加減預算"
              formatter={f}
              fill="#8fd9d5"
              position="bottom"
              offset={50}
            />
          </>
        )}

        <LabelList
          dataKey="總預算"
          formatter={f}
          fill="#5798c5"
          position="bottom"
          offset={75}
        />
      </Bar>
      {isFinal && (
        <Bar barSize={barSize} dataKey="追加減預算" stackId="a" fill="#8fd9d5">
          <LabelList
            dataKey="adp"
            fill="#fff"
            content={<CustomizedLabelList />}
            position="insideTop"
          />
          <LabelList dataKey="total" content={<CustomizedLabel />} />
        </Bar>
      )}
    </BarChart>
  );
};

export default StackedBarChart;
