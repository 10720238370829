import React, { useEffect, useMemo, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { IconButton, HStack, Spinner, Center } from "@chakra-ui/react";
import first from "lodash/first";
import last from "lodash/last";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Text from "../../components/Text";
import Title from "../../components/Title";
import UnitText from "../../components/UnitText";
import StackedBarChart from "../Charts/StackedBarChart";
import Taiwan from "./Taiwan";
import DownloadButton from "../DownloadButton";
import getChineseNumber from "../../utils/getChineseNumber";

const Legend = ({ color, label, width, height, fontSize, ...props }) => (
  <Flex alignItems={"center"} {...props}>
    <Box
      width={width || "14px"}
      height={height || "10.5px"}
      bg={color}
      mr="0.25em"
    />
    <Text fontSize={fontSize || "1em"} color="custom.legendText">
      {label}
    </Text>
  </Flex>
);

const after = {
  content: '""',
  position: "absolute",
  display: "block",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  opacity: 0.25,
  pointerEvents: "none",
  bgGradient: "linear(to-b, rgba(255,255,255,1), rgba(255,255,255,0))",
  transform: "rotate(45deg) translate(-10px, -10px)",
  transformOrigin: "0 0",
  mixBlendMode: "color-dodge",
};

const bgGradient = "linear(to-r, #ffa323, #ffd31f)";

const ArrowButton = ({ disabled, ...props }) => (
  <IconButton
    isRound
    size="xs"
    color="white"
    bgGradient={bgGradient}
    _after={after}
    _disabled={{
      bgGradient: "linear(to-r, #e0e0e0, #e0e0dd)",
      cursor: "not-allowed",
      _hover: {
        bgGradient: "linear(to-r, #e0e0e0, #e0e0dd)",
      },
    }}
    _hover={{
      bgGradient: bgGradient,
    }}
    _focus={{
      outline: "none",
    }}
    _active={{
      bgGradient: bgGradient,
    }}
    disabled={disabled}
    {...props}
  />
);

const UpSection = ({
  data,
  year,
  years,
  city,
  category,
  typeLabel,
  setYear,
  setCity,
  filterCities,
  ready,
  budgetCategory,
}) => {
  const isFinal = budgetCategory === "final";
  const chartRef = React.useRef();
  const recentData = useMemo(
    () =>
      data &&
      data?.map((d) => ({ ...d, year: d.year ? `${d.year - 1911}年` : "" })),
    [data]
  );
  const [iter, setIter] = useState(0);
  useEffect(() => {
    setIter((i) => i + 1);
  }, [year]);
  const legend = [
    isFinal && { label: "追加減預算", color: "custom.treeMapGreen.200" },
    { label: "總預算", color: "custom.lightBlue" },
  ].filter(Boolean);
  return (
    <Flex ref={chartRef} px="3.5em" pt="2.125em">
      <Box flex={1} mr="3.25em">
        <Title city={city} category={category} typeLabel={typeLabel} />
        <Taiwan
          cities={filterCities}
          category={category}
          city={city}
          onClick={setCity}
        />
      </Box>
      <Box>
        <Title
          noType
          title={`歲出預算近${getChineseNumber(years.length)}年比較`}
          titleRight={
            <HStack>
              {legend.map((d, i) => (
                <Legend {...d} key={i} />
              ))}
              <UnitText />
              <DownloadButton
                title={`歲出預算近${getChineseNumber(years.length)}年比較`}
                domRef={chartRef}
              />
            </HStack>
          }
        />
        {ready ? (
          <Box.Relative>
            <StackedBarChart
              key={iter}
              data={recentData}
              yearLength={years.length}
              isFinal={isFinal}
            />
            <Box.Absolute bottom="5px" left="-10%">
              {legend.map((d, i) => (
                <Legend
                  mt={i && "0.5em"}
                  height="12px"
                  width="12px"
                  fontSize="0.75em"
                  {...d}
                  key={d.label}
                />
              ))}
            </Box.Absolute>
            <Box.Absolute bottom="11%" left="-5%">
              <ArrowButton
                disabled={year <= first(years)}
                onClick={() => setYear(year - 1)}
                icon={<MdKeyboardArrowLeft size={"2em"} />}
              />
            </Box.Absolute>
            <Box.Absolute bottom="11%" right="1%">
              <ArrowButton
                disabled={year >= last(years)}
                onClick={() => setYear(year + 1)}
                icon={<MdKeyboardArrowRight size={"2em"} />}
              />
            </Box.Absolute>
          </Box.Relative>
        ) : (
          <Center py="3em">
            <Spinner />
          </Center>
        )}
      </Box>
    </Flex>
  );
};

export default UpSection;
