import React, { useMemo, useState, useEffect } from "react";
import { Accordion } from "@chakra-ui/react";
import sortBy from "lodash/sortBy";

import Box from "../../components/Box";
import useGetData from "../../contexts/useGetData";
import BudgetAnalytics from "./BudgetAnalytics";
import BudgetUseForAnalytics from "./BudgetUseForAnalytics";
import Compare from "./Compare";
import PieChartAnalytics from "./PieChartAnalytics";

const transformData = (data) =>
  typeof data === "undefined"
    ? null
    : data?.reduce((res, d, i) => {
        if (d?.data.length) {
          d?.data.forEach((v) => {
            const filterData = res.filter((d) => d.name === v.name);
            if (filterData.length) {
              filterData[0].total = filterData[0].total + +v.value;
            } else {
              res.push({ name: v.name, total: v.value });
            }
          });
        }
        return res;
      }, []);

const Application = ({
  filterCities,
  category,
  type,
  typeLabel,
  year,
  city,
  cities,
  setCity,
  budgetCategory,
}) => {
  const [compare, setCompare] = useState(0);
  const [item, setItem] = useState();
  const [level, setLevel] = useState(0);
  const [recentYear, handleYear] = useState(+year);
  const { intactData: personnelData } = useGetData(
    cities,
    category,
    type,
    year,
    "personnel"
  );
  // const { intactData: now } = useGetData(cities, category, type, year, 'spendingDetails')
  const { intactData: upnow } = useGetData(
    cities,
    category,
    type,
    recentYear,
    "spending-details"
  );
  useEffect(() => {
    handleYear(+year);
  }, [year]);
  const allTotalBudget = useMemo(
    () => personnelData && transformData(personnelData),
    [personnelData]
  );
  const filterCityTotalBudget = useMemo(
    () =>
      personnelData &&
      city &&
      personnelData
        ?.filter((d) => d?.city === city?.[1])?.[0]
        ?.data.map((d) => ({ ...d, total: d.value })),
    [city, personnelData]
  );
  const usedOptions = useMemo(
    () =>
      upnow &&
      upnow.reduce((res, data) => {
        data.data.forEach((d) =>
          d.children.forEach((c) => {
            if (!res.some((r) => r.name === c.name)) {
              res.push({ id: c.id, name: c.name });
            }
          })
        );
        return res;
      }, []),
    [upnow]
  );
  const spendingDetail = useMemo(() => {
    if (!upnow) return [];
    const nowData = upnow
      .map((d) =>
        item
          ? {
              ...d,
              data: d.data.map((dd) => ({
                ...dd,
                children: dd.children.filter((c) => c.name === item?.[0]),
              })),
            }
          : d
      )
      .map((d) => ({
        name: d.city,
        type: d.type,
        year: d.year,
        id: d.id,
        ...d.data.reduce(
          (res, r) => {
            r.children.forEach((c) => {
              if (r.name === "資本支出") {
                res.cp = res.cp + +c[budgetCategory];
              } else {
                res.ap = res.ap + +c[budgetCategory];
              }
            });
            return res;
          },
          { cp: 0, ap: 0 }
        ),
      }));
    const resData = nowData.map((d) => ({
      ...d,
      rateAp: Math.round((d.ap * 1000) / (d.ap + d.cp)) / 10,
      rateCp: Math.round((d.cp * 1000) / (d.ap + d.cp)) / 10,
    }));
    return resData;
  }, [upnow, item, budgetCategory]);
  const pieChartDataInner = useMemo(() => {
    if (upnow) {
      const totals = [...upnow]
        .filter((d) => (city ? d.city === city?.[1] : d))
        .filter((d) => d?.data.length)
        .reduce((res, d) => {
          d?.data.forEach((c) => {
            if (c.name === "資本支出") {
              // console.log(res[0], res[1], d)
              if (res[0]) {
                res[0][budgetCategory] =
                  +res[0][budgetCategory] + +c[budgetCategory];
                return res;
              } else {
                res[0] = {
                  name: "資本支出",
                  [budgetCategory]: +c[budgetCategory],
                  fill: "#6ECBC6",
                };
                return res;
              }
            } else {
              if (res[1]) {
                res[1][budgetCategory] =
                  +res[1][budgetCategory] + +c[budgetCategory];
                return res;
              } else {
                res[1] = {
                  name: "經常支出",
                  [budgetCategory]: +c[budgetCategory],
                  fill: "#5798C5",
                };
                return res;
              }
            }
          });
          return res;
        }, []);
      return totals;
    }
  }, [upnow, city, budgetCategory]);
  const pieChartSecData = useMemo(() => {
    if (upnow) {
      const filterData = [...upnow].filter((d) =>
        city ? d.city === city?.[1] : d
      );
      const totals = filterData.reduce(
        (res, d) => {
          d?.data.forEach((c) =>
            c.children.forEach((r) => {
              if (c.name === "資本支出") {
                const index = res[0].findIndex((dd) => dd.name === r.name);
                if (index > -1) {
                  res[0][index][budgetCategory] =
                    +res[0][index][budgetCategory] + +r[budgetCategory];
                  if (res[0][index].children && r.children) {
                    r.children.forEach((cc) => {
                      const key = res[0][index].children.findIndex(
                        (rr) => rr.name === cc.name
                      );
                      if (key > -1) {
                        res[0][index].children[key][budgetCategory] =
                          +res[0][index].children[key][budgetCategory] +
                          +cc[budgetCategory];
                      } else {
                        const clonedR = JSON.parse(JSON.stringify(cc));
                        res[0][index].children.push({ ...clonedR });
                      }
                    });
                  }
                } else {
                  const clonedR = JSON.parse(JSON.stringify(r));
                  res[0].push({ ...clonedR, fill: "#48ADA9", type: c.name });
                }
              } else {
                const index = res[1].findIndex((dd) => dd.name === r.name);
                if (index > -1) {
                  res[1][index][budgetCategory] =
                    +res[1][index][budgetCategory] + +r[budgetCategory];
                  if (res[1][index].children && r.children) {
                    r.children.forEach((cc) => {
                      const key = res[1][index].children.findIndex(
                        (rr) => rr.name === cc.name
                      );
                      if (key > -1) {
                        res[1][index].children[key][budgetCategory] =
                          +res[1][index].children[key][budgetCategory] +
                          +cc[budgetCategory];
                      } else {
                        const clonedR = JSON.parse(JSON.stringify(cc));
                        res[1][index].children.push({ ...clonedR });
                      }
                    });
                  }
                } else {
                  const clonedR = JSON.parse(JSON.stringify(r));
                  res[1].push({ ...clonedR, fill: "#0D5A8C", type: c.name });
                }
              }
            })
          );
          return res;
        },
        [[], []]
      );
      const sortTotals = totals.map((arr) =>
        sortBy(arr, (o) => o[budgetCategory])
      );
      const result = sortTotals.flat();
      return result;
    }
  }, [upnow, city, budgetCategory]);
  const pieThreeChartData = useMemo(() => {
    if (pieChartSecData) {
      const data = pieChartSecData.reduce((res, d) => {
        if (d.children) {
          const sortChildren = sortBy(d.children, (o) => o[budgetCategory]);
          if (d.type === "資本支出") {
            const addFillData = sortChildren.map((c) => ({
              ...c,
              fill: "#92E0DA",
            }));
            res.push(...addFillData);
          } else {
            const addFillData = sortChildren.map((c) => ({
              ...c,
              fill: "#71B4CC",
            }));
            res.push(...addFillData);
          }
        } else {
          res.push({ ...d, fill: "#fff" });
        }
        return res;
      }, []);
      return data;
    }
  }, [pieChartSecData, budgetCategory]);
  // const ready = useMemo(() => (upnow || personnelData) && filterCities?.length == personnelData?.length, [personnelData, filterCities, upnow])
  const sectionRef = React.useRef();
  // console.log(allTotalBudget)
  return (
    <Box
      bg="white"
      borderRadius={"0 0 2em 2em"}
      pt="2.75em"
      pb="4em"
      px="3.25em"
    >
      <PieChartAnalytics
        setCity={setCity}
        city={city}
        category={category}
        typeLabel={typeLabel}
        setCompare={setCompare}
        compare={compare}
        setLevel={setLevel}
        level={level}
        year={year}
        budgetCategory={budgetCategory}
        data1={pieChartDataInner}
        data2={pieChartSecData}
        data3={pieThreeChartData}
      />
      <Accordion allowMultiple allowToggle>
        <BudgetAnalytics
          city={city}
          category={category}
          typeLabel={typeLabel}
          // ready={ready}
          totalBudget={filterCityTotalBudget}
          allTotalBudget={allTotalBudget}
          year={year}
        />
      </Accordion>
      <Compare
        cities={cities}
        ref={sectionRef}
        filterCities={filterCities}
        type={type}
        typeLabel={typeLabel}
        year={year}
        city={city}
        setCity={setCity}
        category={category}
        budgetCategory={budgetCategory}
      />
      <BudgetUseForAnalytics
        // data={personnelData}
        year={year}
        setCity={setCity}
        city={city}
        category={category}
        typeLabel={typeLabel}
        setItem={setItem}
        item={item}
        usedOptions={usedOptions}
        spendingDetail={spendingDetail}
      />
    </Box>
  );
};

export default Application;
