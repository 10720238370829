import styled from "@emotion/styled";
import { format } from "d3-format";
import React, { useCallback, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Rectangle,
  Cell,
} from "recharts";

const fontSize = 16;
const lineHeight = 20;
const f = format(",d");
const minPointSize = 56;

const RenderCustomAxisTick = ({
  x,
  y,
  payload,
  width,
  compare,
  onClick,
  index,
  city,
  org,
  left,
}) => {
  const w = fontSize * 4.5;
  const h = fontSize * 2.125;
  const active = compare
    ? !org || !org?.[1] || org?.[1] === index + 1
    : !city || city[2] === index;
  const lines = React.useMemo(() => {
    const ls = payload.value.split("|");
    return (
      <g transform={`translate(0, -${((ls.length - 1) * lineHeight) / 2})`}>
        {ls.map((t, i) => (
          <text
            key={i}
            x={w / 2}
            y={h / 2 + fontSize * 0.35 + i * lineHeight}
            fontSize={fontSize}
            textAnchor="start"
          >
            {t}
          </text>
        ))}
      </g>
    );
  }, [h, payload.value, w]);
  return (
    <>
      <line
        x1={(left || (compare ? 230 : 10)) + width}
        x2={880}
        y1={y}
        y2={y}
        stroke="#ededed"
      />
      <g
        transform={`translate(${
          x - width - fontSize * (compare ? 16 : left ? 4 : 2)
        }, ${y - fontSize * 1.125})`}
        onClick={() => onClick(index)}
        style={{ cursor: "pointer", opacity: active ? 1 : 0.33 }}
      >
        <Rectangle
          radius={8}
          width={w}
          height={h}
          fill="none"
          opacity="0.175"
        />
        {lines}
      </g>
    </>
  );
};

const CustomizedLabel = (props) => {
  const {
    x,
    y,
    height,
    width,
    value,
    setWidth,
    barWidth,
    index,
    org,
    data,
    labelKey,
  } = props;
  useEffect(() => {
    if (barWidth && setWidth) {
      barWidth[index] = width;
      setWidth([...barWidth]);
    }
  }, [org]);
  return (
    !!value && (
      <g>
        <text
          transform={
            data
              ? barWidth?.[index] <= minPointSize && !!data?.[index]?.[labelKey]
                ? `translate(${minPointSize - 4}, 0)`
                : "none"
              : "none"
          }
          x={x + width - (value.toString().length + 1) * 9}
          y={y + height / 2 + 1}
          fill="#fff"
          // textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}%
        </text>
      </g>
    )
  );
};

const BarChartModule = ({
  data,
  onSetCity,
  city,
  compare,
  right,
  left,
  setOrg,
  org,
  dataKey,
  labelColor,
  width,
  labelKey,
  labelPos,
}) => {
  const [barWidth, setWidth] = useState([]);
  useEffect(() => {
    if (org) {
      setWidth([]);
    }
  }, [org]);
  const handleLabelClick = useCallback(
    (index) => {
      if (left) {
        if (org) {
          setOrg(null);
        } else {
          setOrg(data[index].name);
        }
      }
      if (compare) {
        const orgName = data[index].name;
        setOrg(org?.[0] === orgName ? null : [orgName, index + 1]);
      } else {
        const clicked = data[index].id;
        onSetCity(city?.[0] === clicked ? null : clicked);
      }
    },
    [data, onSetCity, city, org, setOrg, compare]
  );
  return (
    <BarChart
      layout="vertical"
      width={width || 880}
      height={data?.length ? Math.max(55, data?.length * 40) : 1000}
      data={data}
      margin={{
        top: 0,
        right,
        left: left || (compare ? 230 : 10),
        bottom: 0,
      }}
    >
      <XAxis
        type="number"
        tick={false}
        tickLine={false}
        axisLine={false}
        padding={{ right: 100 }}
      />
      <YAxis
        tickLine={false}
        axisLine={false}
        dataKey="name"
        type="category"
        cursor={onSetCity || setOrg ? "pointer" : "default"}
        tick={
          data.length && (
            <RenderCustomAxisTick
              left={left}
              compare={compare}
              org={org}
              onClick={handleLabelClick}
              city={city}
            />
          )
        }
      />
      <Bar
        dataKey={dataKey || "total"}
        stackId="a"
        barSize={20}
        fill="#5798C5"
        zIndex={3}
      >
        <LabelList
          dataKey={labelKey || dataKey || "total"}
          position={labelPos || "right"}
          fill={labelColor || "#5798C5"}
          content={
            labelKey && (
              <CustomizedLabel
                setWidth={setWidth}
                barWidth={barWidth}
                org={org}
              />
            )
          }
          formatter={f}
        />
        {data.map((entry, index) => (
          <Cell
            opacity={
              (
                left
                  ? !org || org === data[index].name
                  : compare
                  ? !org?.[1] || org?.[1] === index + 1
                    ? 1
                    : 0.33
                  : !city || city[2] === index
              )
                ? 1
                : 0.33
            }
            key={`cell-${index}`}
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export default BarChartModule;
